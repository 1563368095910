import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import hero from "../images/herobg.png";

const Hero = () => {
    return (
        <div className="MyGradient d-flex justify-content-center" style={{ paddingTop: '2%' }}>
            <div className="position-relative d-flex text-center mx-4 mt-3" style={{ minHeight: '500px', maxWidth: '100%' }}>
                <img src={hero} alt="" className="hero-image" />
                <div className="position-absolute d-flex align-items-between align-items-center" style={{ height: '100%', top: '0', start: '0', background: 'none' }}>
                    <div style={{ width: '15%', background: 'none' }}></div>
                    <div className='d-flex flex-column align-items-center' style={{ width: '70%', background: 'none' }}>
                        <h3 className="hero-text">
	    		   {`In today's digital world, dependable IT support isn't a luxury -
                            it's a necessity. At Techdriven, we specialize in delivering swift,
                            efficient, and hassle-free IT solutions. Our dedicated team of
                            experts is ready to tackle your IT challenges, whether onsite or
                            remote, so you can focus on what you do best: growing your business.
                            Trust Techdriven to keep your technology running smoothly. Why
                            compromise when you can have the best?`}
                        </h3>
                        <Button className="hero-button">
                            <Link className='rounded-pill text-uppercase' to='about-us' smooth style={{ textDecoration: 'none', color: 'white' }}>
                                About Us
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
