import React, { useRef, useState } from 'react';
import { TextField } from '@mui/material';
import axios from 'axios';
import chicago from "../images/chicagoarea.png";

const ContactUs = () => {
    const form = useRef();
    const [message, setMessage] = useState('');

    const sendEmail = async (e) => {
        e.preventDefault();

        const name = e.target.name.value;
        const email = e.target.email.value;
        const userMessage = e.target.message.value;

        try {
            const response = await axios.post('https://techdriven.io/api/send-email', { name, email, message: userMessage });
            setMessage('Email sent successfully');
        } catch (error) {
            setMessage('Error sending email');
        }
        e.target.reset();
    };

    return (
        <div className='MyGradient d-flex justify-content-around align-items-center flex-wrap'>
            <form class="mbr-form text-center" ref={form} onSubmit={sendEmail} style={{ minWidth: '350px' }}>
                <h1 style={{ paddingTop: '50px', marginBottom: '25px', color: '#007F52' }}>Have any questions?</h1>
                <div class="d-flex justify-content-center" style={{ width: '100%' }}>
                    <div class="mx-4 multi-horizontal" data-for="name">
                        <TextField label='Full Name' inputProps={{ style: { color: 'white' } }} color="success" type='text' focused required={true} name="name" />
                    </div>
                    <div class="mx-4 multi-horizontal" data-for="email">
                        <TextField label='Your Email' inputProps={{ style: { color: 'white' } }} color="success" type="email" focused required={true} name="email" />
                    </div>
                </div>
                <div className=' align-items-center d-flex flex-column'>
                    <div class="mx-4 multi-horizontal" style={{ width: '350px' }} data-for="message">
                        <TextField style={{ marginTop: '25px', width: '100%' }} multiline rows={4} label='Message' inputProps={{ style: { color: 'white' } }} color='success' type="text" focused required={true} name="message" />
                    </div>
                    <div class="center">
                        <button href="" type="submit" value='Send' class="btn my-4 justify-content-center" style={{ color: 'white', backgroundColor: '#007F52', borderRadius: '10px' }}>SEND</button>
                    </div>
                </div>
            </form>
            <img src={chicago} style={{ height: '500px', minWidth: '350px', objectFit: 'cover', boxShadow: "0px 0px 25px 5px #007F52", borderRadius: '15px' }} alt="Responsive" />
        </div>
    )
}

export default ContactUs;
