import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../images/techdriven.png';

const pages = ['Services', 'About Us', 'Contact Us'];

function ResponsiveAppBar() {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElServices, setAnchorElServices] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setAnchorElServices(null);
    };

    const handleOpenServicesMenu = (event) => {
        setAnchorElServices(event.currentTarget);
    };
    const handleCloseServicesMenu = () => {
        setAnchorElServices(null);
    };

    const services = [
        {
            title: 'Onsite IT Support',
        },
        {
            title: 'Remote IT Support',
        },
        {
            title: 'IT Consulting',
        }
    ];


    return (
        <AppBar className='Appbar navbar-dark px-4' position="fixed" style={{ height: '60px' }}>
            <Toolbar disableGutters={!isMobile}>
                {/* BIG */}
                <Typography noWrap component="a" href="" sx={{ width: '250px', display: { xs: 'none', md: 'flex' } }}>
                    <Link to='/'><img src={Logo} className='app-logo' alt="" style={{ width: '250px', borderRadius: '10px', objectFit: 'contain' }} /></Link>
                </Typography>

                {/* BIG */}
                <Box sx={{ px: 2, justifyContent: 'end', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <div className='d-flex justify-content-around' style={{ width: '80%' }}>
                        {pages.map((page) => (
                            <div>
                                {page === 'Services' ? (
                                    <MenuItem class='navbar-nav' style={{ borderRadius: '10px', color: '#007F52', display: 'block' }}>
                                        <Link className='nav-link h4' onClick={handleOpenServicesMenu} style={{ color: '', textDecoration: 'none', display: 'block' }}>
                                            {page}
                                            <ExpandMoreIcon style={{ width: '25px', height: '25px' }}></ExpandMoreIcon>
                                        </Link>
                                        {/* </MenuItem> */}
                                        <Menu anchorEl={anchorElServices} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                            open={Boolean(anchorElServices)} onClose={handleCloseServicesMenu} PaperProps={{ style: { background: '#007F52' } }}>
                                            {services.map((service) => (
                                                <MenuItem key={service.title} >
                                                    <Link className=' mx-1 h4' onClick={handleCloseServicesMenu} style={{ color: 'white', textDecoration: 'none' }} to={`/${page.toLowerCase()}/${service.title.toLowerCase().replace(/\s/g, '-')}`}>
                                                        {service.title}
                                                    </Link>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </MenuItem>
                                ) : (
                                    <MenuItem className='navbar-nav' style={{ borderRadius: '15px', color: '#007F52', display: 'block' }}>
                                        <Link class='nav-link h4' style={{ color: '', textDecoration: 'none' }} to={`/${page.toLowerCase().replace(/\s/g, '-')}`}>{page}</Link>
                                    </MenuItem>
                                )
                                }
                            </div>
                        ))}
                    </div>
                </Box>

                {/* SMALL */}
<Typography noWrap component="a" href="" sx={{ my: 1, display: { xs: 'flex', md: 'none' }, flexGrow: 1, width: '100%' }}>
    <Link to='/'><img src={Logo} className='' alt="" style={{ width: '100%', maxWidth: '225px', borderRadius: '10px', objectFit: 'contain', overflow: 'visible' }} /></Link>
</Typography>
                {/* SMALL */}
                <Box sx={{ px: 2, justifyContent: 'end', flexGrow: 1, display: { xs: 'flex', md: 'none', width: '30%' } }}>
                    <MenuItem aria-controls="menu-appbar" onClick={handleOpenNavMenu} sx={{ borderRadius: '15px' }}>
                        <MenuIcon style={{ height: '40px', width: '40px', color: 'white', border: 'none' }} />
                    </MenuItem>
                    <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}
                        PaperProps={{ style: { background: '#007F52' } }} sx={{ display: { xs: 'block', md: 'none' } }}>
                        {pages.map((page) => (
                            <div>
                                {page === 'Services' ? (
                                    <MenuItem>
                                        <Link className='mx-1 h4' onClick={handleOpenServicesMenu} style={{ color: 'white', textDecoration: 'none' }}>
                                            {page}
                                            <ExpandMoreIcon style={{ width: '25px', height: '25px' }}></ExpandMoreIcon>
                                        </Link>
                                        <Menu anchorEl={anchorElServices} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(anchorElServices)}
                                            onClose={handleCloseServicesMenu} PaperProps={{ style: { background: '#007F52' } }}>
                                            {services.map((service) => (
                                                <MenuItem key={service.title} onClick={handleCloseNavMenu}>
                                                    <Link className='mx-1 h4' style={{ color: 'white', textDecoration: 'none' }} to={`/${page.toLowerCase()}/${service.title.toLowerCase().replace(/\s/g, '-')}`}>
                                                        {service.title}
                                                    </Link>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </MenuItem>
                                ) : (
                                    <div>
                                        <MenuItem>
                                            <Link onClick={handleCloseNavMenu} class='mx-1 h4' style={{ color: 'white', textDecoration: 'none' }} to={`/${page.toLowerCase().replace(/\s/g, '-')}`}>{page}</Link>
                                        </MenuItem>
                                    </div>
                                )
                                }
                            </div>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar >
    );
}
export default ResponsiveAppBar;
