import React from 'react';
import { Routes, Route, Link, useParams } from 'react-router-dom';
import remote from "../images/remotework.png";
import remcons from "../images/remcons.png";
import onsite from "../images/consult.png";
import onsite2 from "../images/onsiteconsult.png";
import consult from "../images/cyberphone.png";
import hololap from '../images/laptopholo.png';

const ServiceDetails = ({ services }) => {
    const { serviceId } = useParams();
    console.log('here is serviceID ->', serviceId)
    const service = services.find(item => item.title.toLowerCase().replace(/\s/g, '-') === serviceId);
    console.log('here is serviceID ->', service)

    return (
        <div className='MyGradient text-center' style={{ width: '100%' }}>
            <h1 className='text-uppercase p-2 pt-5' style={{ color: '#007F52' }}>{service.title}</h1>
            {service.title === 'Remote IT Support' ? (
                <div className='MyGradient p-5 d-flex flex-wrap justify-content-around align-items-center'>
                    <div className='d-flex flex-column justify-content-center align-items-center text-justify' style={{ maxWidth: '600px' }}>
                        <p className='h4 mt-2' style={{ width: '85%' }}>{service.description}</p>
                        <div>
                            <Link className='btn rounded-pill nav-link mt-2' style={{ border: '1px 1px', borderColor: '#007F52', width: '100%', color: '#007F52' }} to='/'>Back to Home</Link>
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <img src={service.image} alt="" style={{ marginTop: '25px', maxWidth: '350px', height: '100%', objectFit: 'contain' }} />
                        <img src={service.image2} alt="" style={{ marginTop: '25px', maxWidth: '350px', height: '100%', objectFit: 'contain', borderRadius: '15px', boxShadow: "0px 5px 10px #007F52" }} />
                    </div>
                </div>
            ) : (
                <div className='MyGradient p-5 d-flex flex-wrap justify-content-around align-items-center'>
                    <div className='d-flex flex-column mb-5'>
                        <img src={service.image} alt="" style={{ marginTop: '25px', maxWidth: '350px', height: '100%', objectFit: 'contain', borderRadius: '15px', boxShadow: "5px 5px 20px #007F52" }} />
                        <img src={service.image2} alt="" style={{ marginTop: '25px', maxWidth: '350px', height: '100%', objectFit: 'contain', borderRadius: '15px', boxShadow: "5px 5px 20px #007F52" }} />
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center text-justify' style={{ maxWidth: '600px' }}>
                        <p className='h4' style={{ width: '85%' }}>{service.description}</p>
                        <div>
                            <Link className='btn rounded-pill nav-link mt-2' style={{ border: '1px 1px', borderColor: '#007F52', width: '100%', color: '#007F52' }} to='/'>Back to Home</Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const Services = () => {
    const services = [
        {
            title: 'Onsite IT Support',
            description: "When technical challenges demand an in-person approach, Techdriven's Onsite IT Support is ready to spring into action. Our Chicago-based team offers swift and reliable on-ground support to solve your IT issues without delay. From network troubleshooting to hardware and software solutions, our hands-on expertise ensures minimal downtime, keeping your business operations running smoothly.",
            link: '/',
            image: onsite,
            image2: onsite2
        },
        {
            title: 'Remote IT Support',
            description: "Get expert IT assistance without the need for a physical visit. Techdriven's Remote IT Support combines cutting-edge technology with a human touch to deliver efficient, timely, and effective solutions. No matter where your business is located, we can remotely diagnose and fix your IT problems, offering the convenience of immediate assistance at your fingertips.",
            link: '/',
            image: remote,
            image2: remcons
        },
        {
            title: 'IT Consulting',
            description: "Stay ahead of the tech curve with Techdriven's IT Consulting services. We work closely with your team to understand your business goals and design custom technology strategies that drive growth and success. Whether you need advice on IT infrastructure, cybersecurity, software selection, or digital transformation, we offer insights and guidance tailored to your specific needs.",
            link: '/',
            image: consult,
            image2: hololap
        }
    ];

    return (
        <Routes>
            <Route path="/" element={<ServiceDetails services={services} />} />
            <Route path=":serviceId" element={<ServiceDetails services={services} />} />
        </Routes>
    );
}

export default Services;
