import { Link } from 'react-router-dom';

const Attributions = () => {

    const openInNewTab = () => {
        const newWindow = window.open('https://www.freepik.com/', '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className="MyGradient">
            <p className="text-center pt-5">Some pictures used in the website come directly from <br />
                <Link href="#" onClick={() => openInNewTab()}>https://www.freepik.com/</Link></p>
        </div>
    )
}

export default Attributions;