import React from 'react';
import '../App.css';
import chicago from "../images/chicagoarea.png";
import working from "../images/workingenv.png";
import professionals from "../images/teamdesk.png";
import wheel from "../images/iconwheel.png";

const AboutUs = () => {
    const Features = [
        {
            image: chicago,
            text: "Our team comprises dedicated professionals who bring together a diverse range of skills and experience in IT consulting and technical support. Every member is committed to staying ahead of the tech curve, constantly updating our knowledge and refining our skills to ensure we provide our clients with cutting-edge solutions."
        }, {
            image: wheel,
            text: "Rooted in the Midwestern values of hard work and integrity, we pride ourselves on our reputation for reliability, promptness, and outstanding customer service. With Techdriven, you're not just hiring an IT company - you're partnering with a team that's genuinely invested in your business's success."
        }, {
            image: professionals,
            text: "At Techdriven, we believe in the power of technology to transform businesses, and we're dedicated to making that transformation seamless and stress-free for our clients."
        }
    ]

    return (
        <div className="MyGradient p-5 align-items-center text-center">
            <h1 className='' style={{ color: '#007F52' }}>ABOUT US</h1>
            <div className="d-flex flex-wrap justify-content-center align-items-center mt-5" style={{ height: '50%' }}>
                {Features.map((feature, index) => (
                    <React.Fragment key={index}>
                        <div className="text-justify p-4" style={{ width: '33.3%', minWidth: '350px' }}>
                            <h4>{feature.text}</h4>
                        </div>
                        <div className="" style={{ width: '33.3%', minWidth: '350px' }}>
                            <img src={feature.image} style={{ width: '100%', minHeight: '350px', maxHeight: '400px', objectFit: 'cover', boxShadow: "0px 0px 20px 5px #007F52", borderRadius: '15px' }} alt="Responsive" />
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default AboutUs;
