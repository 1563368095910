import React from 'react';
import Hero from './components/Hero';
import Appbar from './components/Appbar';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import ContactUs from './components/ContactUs';
import './App.css';
import { Element } from 'react-scroll';
import { Link, BrowserRouter, Routes, Route } from 'react-router-dom';
import Attributions from './components/Attributions';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {

  return (
    <BrowserRouter basename="">
      <div className="App">
        <head>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />
          <title>TechDriven</title>
        </head>

        <body style={{}}>
          <header className="" style={{ marginBottom: '60px' }}>
            <Appbar></Appbar>

          </header>
          <main>
            <Routes>
              <Route path="/" element={<Hero />} />
              <Route path="/services/*" element={<Services />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/contact-us' element={<ContactUs />} />
              <Route path='/attributions' element={<Attributions />} />
              <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            </Routes>

            <Element name="contact">
              <Contact />
            </Element>

          </main>
          <footer className="pb-1" style={{ background: 'rgb(12,12,12)' }}>
            <div className=" nav justify-content-around border-bottom pb-3 mb-3" style={{ width: '100%' }}>
              <li className="nav-item"><Link to="privacypolicy" className="nav-link px-2 text-muted">Privacy Policy</Link></li>
              <li className="nav-item"><Link to="/" className="nav-link px-2 text-muted">Terms of Service</Link></li>
              <li className="nav-item"><Link to="/" className="nav-link px-2 text-muted">Sitemap</Link></li>
              <li className="nav-item"><Link to="attributions" className="nav-link px-2 text-muted">Attributions</Link></li>
            </div>
            <p className="text-center text-muted">&copy; TechDriven - All Rights Reserved </p>
          </footer>
        </body>

      </div>
    </BrowserRouter >
  );
}

export default App;