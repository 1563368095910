import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
// import { Element } from 'react-scroll';

const Contact = () => {
    const contactItems = [
        {
            title: 'Phone',
            content: '+1 855-566-2364'
        },
        {
            title: 'Address',
            content: 'Greater Chicago Land Area'
        },
        {
            title: 'Email',
            content: 'info@techdriven.io'
        },
        {
            title: 'Form',
            content: 'Contact Us'
        }
    ];

    return (
        <div className="Contact p-4 d-flex flex-wrap justify-content-around text-center" style={{ height: '100%' }}>
            {contactItems.map((item, index) => (
                <div class='d-flex flex-column align-items-center justify-content-center' key={index} style={{ minWidth: '35%', maxWidth: '45%' }}>
                    {item.content === 'Contact Us' ? (
                        <div className='d-flex flex-column align-items-center'>
                            <h4 class='h3 border-bottom mx-5 mt-4 mb-2 '>{item.title}</h4>
                            <Button style={{ width: '85%', height: '100%', borderRadius: '15px', color: '#007F52', backgroundColor: '#007F52', display: 'block', textDecoration: 'none' }}>
                                <Link className='rounded-pill text-uppercase' style={{ color: 'white', textDecoration: 'none' }} to={`/${item.content.toLowerCase().replace(/\s/g, '-')}`} smooth>
                                    {item.content}
                                </Link>
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <h4 class='h3 border-bottom mx-5 my-4'>{item.title}</h4>
                            <p class='h6'>{item.content}</p>
                        </div>

                    )}
                </div>
            ))}
        </div>
    );
}

export default Contact; 